import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { SwiperSlide } from "swiper/react";
import { NewsletterButton, useGlobalContext } from "~/providers";
import { NavLink } from "../Buttons";
import { Partners } from "../Layout";
import Link from "next/link";

export const FooterPanel = () => {
  const { footer } = useGlobalContext();
  return (
    <Box
      as={SwiperSlide}
      w={{ base: "1780px", md: "2000px" }}
      h="100vh"
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      backgroundImage="/FinalSection.png"
      backgroundSize={"cover"}
    >
      <Flex
        direction={{ base: "row" }}
        align={{ base: "center", md: "flex-end" }}
        p="32px"
        pb={{ md: "62px", lg: "32px" }}
      >
        <Center flexDirection="column" w="390px">
          <Partners title="Partners" images={footer} mb={{ base: "80px" }} />

          <Text noOfLines={1} fontSize="md">
            {`© ${new Date().getFullYear()} Highway 3`}
          </Text>
          <NavLink fontSize="md" noOfLines={1} href={"/privacy-policy"}>
            Privacy Policy
          </NavLink>
        </Center>
        <Center flexDirection="column" w="390px">
          <Image src="ShieldLogo.png" alt="" mb="32px" w="179px" />
          <Link target="_blank" href={"https://www.hellobc.com/ "}>
            <Image src="SNBCLogo.png" alt="" w="217px" mb={{ base: "80px" }} />
          </Link>
          <NewsletterButton />
        </Center>

        <Center flexDirection="column" w="390px">
          <Flex
            direction={{ base: "column" }}
            justifyContent={{
              base: "space-evenly",
              md: "center",
              lg: "space-evenly",
            }}
            paddingTop={{ base: 20 }}
            paddingBottom={{ base: 10 }}
            width={{ base: "100%", lg: "30%" }}
            style={{
              alignItems: "center",
              gap: 20,
            }}
          >
            <Link style={{ fontSize: 20 }} color="gray.900" href={"/about-us"}>
              About Us
            </Link>
            <Link
              style={{ fontSize: 20 }}
              color="gray.900"
              href={"/contact-us"}
            >
              Contact Us
            </Link>
          </Flex>
          <Partners
            images={footer}
            title="Supported By"
            mb={{ base: "80px" }}
          />

          <NavLink
            fontSize="md"
            target="_blank"
            noOfLines={1}
            href={"https://thenumber.ca/"}
          >
            Made by The Number
          </NavLink>
        </Center>
      </Flex>
    </Box>
  );
};

FooterPanel.displayName = "SwiperSlideFooterPanel";
