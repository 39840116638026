import { Box, BoxProps, Heading } from "@chakra-ui/react";
import { usePageContext } from "~/providers";
import { Subheading } from "../../Text/Subheading";
export const StoriesIntroSection = (props: BoxProps) => {
  const { title, description } = usePageContext();
  const titleWords = title?.split(" ");

  if (!title && !description) return null;
  return (
    <Box
      w={{ base: "100%", md: "90%" }}
      bg="gray.900"
      pb={{ base: "72px", md: "104px" }}
    >
      {titleWords && (
        <Box mb={{ base: "56px" }} mt={{ base: "32px" }}>
          <Heading
            color="yellow.500"
            fontSize={{ base: "6xl", md: "8xl", lg: "6xl" }}
            as="span"
          >
            {`${titleWords[0]} `}
          </Heading>
          <Heading
            color="white"
            fontSize={{ base: "6xl", md: "8xl", lg: "6xl" }}
            fontWeight="thin"
            as="span"
          >
            {` ${titleWords.slice(1).join(" ")}`}
          </Heading>
        </Box>
      )}
      <Box width={{ base: "100%", md: "90%" }} maxW={"600px"}>
        {description
          ?.split("\n")
          .slice(1)
          .map((description, i) => {
            if (description === "") return <br key={i} />;
            return (
              <Subheading
                key={i}
                fontSize={{ base: "lg", md: "2xl" }}
                color="white"
              >
                {description}
              </Subheading>
            );
          })}
      </Box>
    </Box>
  );
};
