import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { SwiperSlide } from "swiper/react";
import { Subheading } from "~/components";
import { useGlobalContext } from "~/providers";
import { Markdown } from "~/strapi/components/Markdown";

export const SecondPanel = () => {
  const { home } = useGlobalContext();
  const columnWidth = useBreakpointValue({ base: "50vw" });
  return (
    <Box
      as={SwiperSlide}
      w="auto"
      h="auto"
      display="flex"
      backgroundColor="blue.400"
      py={{ base: "32px" }}
      px={{ base: "72px" }}
      pt={150}
    >
      <Flex
        gap={{ base: 5, md: 10 }}
        direction={{ base: "row" }}
        h="100%"
        pr={2}
        align={{ base: "flex-end", md: "center", lg: "flex-end" }}
      >
        <Box
          display="flex"
          flexDir="column"
          justifyContent="center"
          h={{ base: "100%" }}
          mt={{ md: "-50px" }}
        >
          <Box
            h={{ base: 180, md: 200 }}
            mb={5}
            w={{ base: 286, md: 570, lg: 690 }}
            position="relative"
          >
            <Text
              fontStyle="var(--font-lexend)"
              fontWeight={700}
              position="absolute"
              fontSize={{ base: "6xl", md: "7xl" }}
              top={0}
              left={0}
            >
              Drive and
            </Text>
            <Text
              fontStyle="var(--font-lexend)"
              fontWeight={700}
              position="absolute"
              bottom={0}
              left={{ base: 0, md: 80, lg: 350 }}
              fontSize={{ base: "6xl", md: "7xl" }}
            >
              Discover!
            </Text>
            <Image
              src="/homeRoadLine.png"
              alt=""
              h={{ base: 20, md: 82, lg: 100 }}
              mt={{ base: 10, md: 55 }}
              w={{ base: 250, md: 570, lg: 590 }}
            />
          </Box>

          <Subheading
            fontSize={{ base: "3xl" }}
            color="yellow.500"
            maxW={{ base: 310, md: 500 }}
          >
            the route you take for old stories, new memories & breathtaking
            landscapes
          </Subheading>
        </Box>

        <Box
          maxW={{ base: "200vw", md: "120vw" }}
          marginBottom={{ base: 2, md: 5 }}
          display={{ base: "flex", lg: "none" }}
        >
          <Text
            fontWeight="thin"
            whiteSpace="pre-wrap"
            fontSize="md"
            style={{
              columnWidth,
              columnFill: "balance-all",
            }}
          >
            {home?.descriptionHome}
          </Text>
        </Box>
      </Flex>
      <Box
        style={{ maxWidth: "48vw" }}
        display={{ base: "none", lg: "flex" }}
        alignItems="center"
      >
        <Markdown>{home?.descriptionHome}</Markdown>
      </Box>
    </Box>
  );
};

SecondPanel.displayName = "SwiperSlideSecondPanel";
